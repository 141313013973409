<template>
  <div class="solution">
    <div class="swiper-box">
      <swiper :options="swiperOption"
              ref="mySwiper"
              class="swiper-wrapper"
              @slide-change-transition-end="handleClickSlide">
        <swiper-slide v-for="(item, index) in swiperList.items"
                      :key="index"
                      class="swioer-item"
                      swiper-animate-effect="shutter2">
          <div class="swiper-box">
            <img :src="item.imgs?(JSON.parse(item.imgs)[langtype]?JSON.parse(item.imgs)[langtype]:item.img):item.img "
                 alt="" />
            <!-- <div :class="'center' + index">
              <p class="name">{{item.name}}</p>
              <p class="title">{{item.title}}</p>
              <p class="description">{{item.description}}</p>
              <div class="btn-box" v-if="index !== 0">
                <span>立即选购 </span>|
                <span>  了解详情</span>
              </div>
            </div> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
      <!-- <div class="sign-box">
        <img class="sign" src="../assets/home/shubiao.png" alt="" />
      </div> -->
    </div>
    <!-- <div class="inbanner clearfix">
        <div class="img"><img :src="planTop.img" alt=""></div>
        <div class="box">
            <div class="bt wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.2s">
                {{solutionList.title}}
            </div>
        </div>
      </div> -->
    <div class="tab-box-min">
      <ul>
        <li v-for="(item) in solutionList.items"
            :key="item.id"
            @click="viewMore(item)">
          <img :src="item.coverImgs?JSON.parse(item.coverImgs)[langtype]||item.coverImg:''"
               alt="">
          <div class="title">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:''}}</div>
          <div class="description">{{item.descriptions?JSON.parse(item.descriptions)[langtype]||item.description:''}}</div>
        </li>
      </ul>
    </div>
    <div class="tab-box">
      <ul>
        <li v-for="(item,index) in solutionList.items"
            :key="item.id">
          <div class="even"
               v-if="index%2 === 0">
            <div class="content">
              <p class="name">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:''}}</p>
              <div class="border"></div>
              <p class="keyword">{{item.descriptions?JSON.parse(item.descriptions)[langtype]||item.description:''}}</p>
              <el-button id="button1"
                         class="primary"
                         type="primary"
                         @click="viewMore(item)">{{$t('solution.ckgd')}}</el-button>
            </div>
            <el-image :src="item.coverImgs?JSON.parse(item.coverImgs)[langtype]||item.coverImg:''"
                      alt=""
                      lazy></el-image>
          </div>
          <div class="even odd"
               v-else>
            <el-image :src="item.coverImgs?JSON.parse(item.coverImgs)[langtype]||item.coverImg:''"
                      alt=""
                      lazy></el-image>
            <div class="content right">
              <p class="name">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:''}}</p>
              <div class="border"></div>
              <p class="keyword">{{item.descriptions?JSON.parse(item.descriptions)[langtype]||item.description:''}}</p>
              <el-button id="button1"
                         class="primary"
                         type="primary"
                         @click="viewMore(item)">{{$t('solution.ckgd')}}</el-button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'BbjtwebsiteSolution',
  components: { swiper, swiperSlide },
  data () {
    return {
      solutionList: [],
      planTop: [],
      langtype: 0,
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: []
    };
  },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
  },
  mounted () {
    this.getDataList()
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    handleClickSlide () { },
    async getDataList () {
      let data = {
        layout_id: 11,
      }
      let solutionList = await this.api.post(this.apiPath.editor, data);

      let planTop = await this.api.get(this.apiPath.planTop, {});

      this.planTop = planTop[0]
      this.solutionList = solutionList
      console.log(this.solutionList)
      let res = await this.api.get(this.apiPath.solutionBannerList, {});
      console.log(res)
      this.swiperList = res;
      if (res.items.length == 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }
    },
    viewMore (item) {
      this.$router.push({
        path: "solutionDetail?id=" + item.id,
      });
      // document.documentElement.scrollTop=700;
    }
  },
};
</script>

<style lang="less" scoped>
.tab-box-min {
  display: none;
  margin-top: 20px;
}
.inbanner {
  margin-bottom: 100px;
}
.tab-box {
  width: 85%;
  margin: auto;
  margin-top: 100px;
  .even {
    width: 100%;
    display: flex;
    margin-bottom: 100px;
    .content {
      width: 50%;
      margin-right: 10%;
      position: relative;
      .name {
        font-weight: bold;
        font-size: 16px;
      }
      .border {
        width: 60px;
        height: 2px;
        background: #7f0114;
        margin-bottom: 20px;
      }
      .keyword {
        display: block;
        font-size: 14px;
        height: 229px;
        // border: 1px solid #000;
        display: -webkit-box; // 元素转换为弹性容器,在一行排列
        -webkit-box-orient: vertical;
        word-break: break-all;
        -webkit-line-clamp: 11;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .primary {
        position: absolute;
        bottom: 0px;
      }
    }
    .right {
      width: 75%;
      margin-left: 20px;
    }
    img {
      width: 40%;
      margin-top: 20px;
      float: right;
    }
  }
  .odd {
    margin-bottom: 100px;
    .content {
      width: 50%;
      margin-left: 10%;
      margin-right: 0;
    }
    img {
      width: 40%;
      height: 250px;
      margin-top: 20px;
      float: right;
    }
  }
}
@media screen and (max-width: 750px) {
  .tab-box {
    display: none;
  }
  .inbanner {
    margin-bottom: 20px;
  }
  .tab-box-min {
    display: block;
    padding: 0 15px;
    ul li {
      margin-bottom: 20px;
      border-bottom: 2px solid #eee;
      padding-bottom: 5px;
      .title {
        font-size: 18px;
        text-align: center;
        color: #7f0114;
        font-weight: bold;
      }
    }
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
<style>
#button1 {
  width: 100px;
  height: 34px;
  color: #fff;
  line-height: 10px;
  background-color: #7f0114;
  border-color: #7f0114;
}
#button1:hover {
  background-color: #9a6a71;
}
</style>
