<template>
  <div class="about">
    <div class="swiper-box">
      <swiper :options="swiperOption"
              ref="mySwiper"
              class="swiper-wrapper"
              @slide-change-transition-end="handleClickSlide">
        <swiper-slide v-for="(item, index) in swiperList.items"
                      :key="index"
                      class="swioer-item"
                      swiper-animate-effect="shutter2">
          <div class="swiper-box">
            <img :src="item.imgs?(JSON.parse(item.imgs)[langtype]?JSON.parse(item.imgs)[langtype]:item.img):item.img"
                 alt="" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
    </div>
    <div class="tab-box1">
      <ul>
        <li v-for="(item, index) in tabList"
            :class="[tabShou === index ? 'active' : '']"
            @click="addTab(item, index)"
            :key="index">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="about-cen">
      <div class="tab">
        <el-breadcrumb class="breadcrumb"
                       separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{$t('nav.home')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$t('nav.gybb')}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ location }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="title-box" >
          <p class="title">{{$t('nav.gybb')}}</p>
          <p class="name">About</p>
        </div>
        <div class="tab-box">
          <ul>
            <li v-for="(item, index) in tabList"
                :class="[tabShou === index ? 'active' : '']"
                @click.stop="addTab(item, index)"
                :key="index">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="center"
           v-if="tabShou === 0">
        <div class="title-img">
          <img src="../../assets/about/title1.jpg" alt="" />
        </div>
        <div v-for="(item, index) in introduction"
             :key="index">
          <p class="description"
             v-html=" item.detailses.replace('佳智惠','佳智惠')?JSON.parse(item.detailses.replace('佳智惠','佳智惠'))[langtype]:'' "></p>
        </div>
      </div>
      <div class="center"
           v-if="tabShou === 1">
        <div class="title-img">
          <img src="../../assets/about/title2.jpg" alt="" />
        </div>
        <div class="culture-box">
          <div v-for="(item, index) in culture"
               :key="index">
            <p class="description"
               v-html=" item.detailses.replace('佳智惠','佳智惠')?JSON.parse(item.detailses.replace('佳智惠','佳智惠'))[langtype]:'' "></p>
          </div>
        </div>
      </div>
      <div class="center"
           v-if="tabShou === 2">
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + titleImg + ')' }"></div>
        <div class="qualification-box">
          <div class="qualification"
               v-for="(item, index) in qualification.items"
               :key="index">
            <el-image class="img"
                      :src="item.img"
                      :preview-src-list="srcList"></el-image>
            <p class="qname">{{ item.names?(JSON.parse(item.names)[langtype]?JSON.parse(item.names)[langtype]:item.name):item.name}}</p>
          </div>
        </div>
      </div>
      <div class="center"
           v-if="tabShou === 3">
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + titleImg + ')' }"></div>
        <div class="info wow fadeInUp2"
             data-wow-duration="1.5s"
             data-wow-delay="0.1s">
          <div class="container"
               v-for="(item, index) in contact.items"
               :key="index">
            <div class="about-bottom">
              <div class="about-left">
                <div class="box">
                  <div class="text t1">
                    <i class="el-icon-phone" style="margin-right:5px"></i>{{$t('about.dh')}}: {{item.names?(JSON.parse(item.names)[langtype]?JSON.parse(item.names)[langtype]:item.name):item.name}}
                  </div>
                  <div class="text t2">
                    <i class="el-icon-message"  style="margin-right:5px"></i>{{$t('about.yx')}}: {{  item.expansions?(JSON.parse(item.expansions)[langtype]?JSON.parse(item.expansions)[langtype]:item.expansion):item.expansion}}
                  </div>
                  <div class="text t3">
                    <svg t="1693988057711" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4012" width="25" height="25"><path d="M1011.296677 412.899958 607.720062 98.818633c-58.950623-46.784536-132.378983-46.904263-191.095269-0.177032L12.81384 412.899958c-13.114692 10.160407-15.358803 30.873148-5.139044 43.809785 10.278087 12.995989 29.299303 17.10968 42.176588 7.125281l78.413907-61.024865 0 367.366925c0 121.391744 51.42523 188.743712 173.997869 188.743712l423.422615 0c122.573663 0 169.83199-69.88465 169.83199-191.334723L895.517767 402.582985l78.682014 61.252039c5.434779 4.253884 11.990079 6.323009 18.548448 6.323009 8.859785 0 17.721617-4.924149 23.629163-12.424984C1026.596128 444.797435 1024.352017 423.061388 1011.296677 412.899958zM639.705544 895.579165 383.741871 895.579165 383.741871 639.492696l255.963673 0L639.705544 895.579165zM831.761698 766.780731c0 79.670527-30.529317 128.798435-106.074897 128.798435l-22.01541 0 0-258.855536c-0.085958-2.705622-2.65548-60.789505-60.487629-60.789505L380.26263 575.934125c2.303462 0-60.488653-1.299599-60.488653 60.488653l0 259.156388-17.510816 0c-67.260893 0-110.643959-51.17452-110.643959-128.255059l-0.046049-413.782048 262.385942-204.199729c36.860513-29.241998 79.271438-29.299303 116.487038 0.176009l261.314541 203.430202L831.760674 766.780731zM703.671391 636.422777l0 0.300852C703.677531 636.916011 703.671391 636.836193 703.671391 636.422777z" p-id="4013" fill="#707070"></path></svg>
                    {{$t('about.zbgsmc')}}: {{  item.zbName?(JSON.parse(item.zbName)[langtype]?JSON.parse(item.zbName)[langtype]:item.zbName[0]):item.JSON.parse(item.zbName[0])}}
                  </div>
                  <div class="text t4">
                    <i class="el-icon-location-information"  style="margin-right:5px"></i>{{$t('about.zbgsdz')}}:
                    {{ item.keywords?(JSON.parse(item.keywords)[langtype]?JSON.parse(item.keywords)[langtype]:item.keyword):item.keyword }}
                  </div>
                  <div class="text t5">
                    <svg t="1693988057711" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4012" width="25" height="25"><path d="M1011.296677 412.899958 607.720062 98.818633c-58.950623-46.784536-132.378983-46.904263-191.095269-0.177032L12.81384 412.899958c-13.114692 10.160407-15.358803 30.873148-5.139044 43.809785 10.278087 12.995989 29.299303 17.10968 42.176588 7.125281l78.413907-61.024865 0 367.366925c0 121.391744 51.42523 188.743712 173.997869 188.743712l423.422615 0c122.573663 0 169.83199-69.88465 169.83199-191.334723L895.517767 402.582985l78.682014 61.252039c5.434779 4.253884 11.990079 6.323009 18.548448 6.323009 8.859785 0 17.721617-4.924149 23.629163-12.424984C1026.596128 444.797435 1024.352017 423.061388 1011.296677 412.899958zM639.705544 895.579165 383.741871 895.579165 383.741871 639.492696l255.963673 0L639.705544 895.579165zM831.761698 766.780731c0 79.670527-30.529317 128.798435-106.074897 128.798435l-22.01541 0 0-258.855536c-0.085958-2.705622-2.65548-60.789505-60.487629-60.789505L380.26263 575.934125c2.303462 0-60.488653-1.299599-60.488653 60.488653l0 259.156388-17.510816 0c-67.260893 0-110.643959-51.17452-110.643959-128.255059l-0.046049-413.782048 262.385942-204.199729c36.860513-29.241998 79.271438-29.299303 116.487038 0.176009l261.314541 203.430202L831.760674 766.780731zM703.671391 636.422777l0 0.300852C703.677531 636.916011 703.671391 636.836193 703.671391 636.422777z" p-id="4013" fill="#707070"></path></svg>
                    {{$t('about.fbgsmc')}}: {{ item.fbName?(JSON.parse(item.fbName)[langtype]?JSON.parse(item.fbName)[langtype]:item.fbName[0]):item.fbName[0] }}
                  </div>
                  <div class="text t6">
                    <i class="el-icon-location-information"  style="margin-right:5px"></i>{{$t('about.fbgsdz')}}: {{ item.fbAddress?(JSON.parse(item.fbAddress)[langtype]?JSON.parse(item.fbAddress)[langtype]:item.fbAddress[0]):item.fbAddress[0] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-map">
          <div id="allmap"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import AMapLoader from "@amap/amap-jsapi-loader";
import AgentForm from "./agentForm.vue";
export default {
  props: [],
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  data () {
    return {
      tabList: [
        { name: "bbjj", img: require("../../assets/about/title1.jpg") },
        { name: "bbwh", img: require("../../assets/about/title2.jpg") },
        { name: "ryzz", img: require("../../assets/about/title5.png") },
        // { name: "招商代理", img: require("../../assets/about/title3.jpg") },
        { name: "lxwm", img: require("../../assets/about/title4.jpg") },
      ],
      advantage: require("../../assets/about/jm.png"),
      policy: require("../../assets/about/zc.png"),
      goal: require("../../assets/about/wm.png"),
      tabShou: 0,
      langtype: 0,
      titleImg: require("../../assets/about/title1.jpg"),
      investmentImg: require("../../assets/about/title6.png"),
      location: "佳智惠简介",
      culture: [],
      inbanner: [],
      introduction: [],
      qualification: [],
      contact: [],
      partnerss: [],
      map: null,
      config: require("../../components/config"),
      infoWindow: "",
      companyInfoList: {},
      srcList: [],
      qufanList:[],
      dadu:'',
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: [
      ]
    };
  },
  name: "videoPLay",
  components: { swiper, AgentForm, swiperSlide },
  created () {
    console.log("tab-",this.$route);
    if (this.$route.query.tab) {
      this.tabShou = parseInt(this.$route.query.tab)
    }else{
      this.tabShou = 0
    }
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    this.tabList.forEach((item, index) => {
      item.name = this.$t('nav.' + item.name)
    })
    this.tabList[0].img = require("../../assets/about/title1_" + this.langtype + ".png")
    this.tabList[1].img = require("../../assets/about/title2_" + this.langtype + ".png")
    this.tabList[2].img = require("../../assets/about/title5_" + this.langtype + ".png")
    this.tabList[3].img = require("../../assets/about/title4_" + this.langtype + ".png")
    this.titleImg = require("../../assets/about/title1_" + this.langtype + ".png")
    if (this.$route.query.tab) {
      this.tabShou = parseInt(this.$route.query.tab);
    }
    this.getlist();
  },
  mounted () {
    this.companyInfoList = JSON.parse(localStorage.getItem("companyInfo"));
    setTimeout(() => { }, 1000);
    const _this = this;
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      // this.$wow.init()
    });
  },
  methods: {
    handleClickSlide () {
      // console.log(222);
    },
    async getlist () {
      let inbanner = await this.api.get(this.apiPath.inbanner, {});
      this.inbanner = inbanner;
      let introduction = await this.api.get(this.apiPath.dynamicDetail + `196` + '/detail', {});
      console.log(introduction)
      this.introduction = [introduction];
      let qualification = await this.api.get(this.apiPath.qualification, {});
      this.qualification = qualification;
      console.log(this.qualification)
      qualification.items.sort((a, b) => { return b.type - a.type })
      console.log(qualification.items)

      for (var item of qualification.items) {
        console.log(item.img)
        this.srcList.push(item.img)
      }
      let culture = await this.api.get(this.apiPath.dynamicDetail + "203" + '/detail', {});
      console.log(culture)
      this.culture = [culture];
      let contact = await this.api.get(this.apiPath.contact, {});
      this.contact = contact;
      console.log(this.contact)
      let partners = await this.api.get(this.apiPath.partners, {});
      this.partnerss = partners.items;
      let res = await this.api.get(this.apiPath.aboutSwiper, {});
      this.swiperList = res;
      console.log(this.swiperList)
      if (res.items.length === 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }
    },
    init () {
      // 初始化地图
      AMapLoader.load({
        key: this.config.aMapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.Geolocation",
          "AMap.Geocoder",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: ["misc/PositionPicker"], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          let that = this;
          this.map = new AMap.Map("allmap", {
            zoom: 15,
            center: [113.856018, 22.588541], //初始化地图中心点
          });
          that.map.addControl(new AMap.ToolBar());
          that.map.addControl(new AMap.Autocomplete());
          that.map.addControl(new AMap.PlaceSearch());
          that.map.addControl(new AMap.Geocoder());

          // 实例化Autocomplete
          let autoOptions = {
            city: "全国",
          };
          that.geocoder = new AMap.Geocoder(autoOptions);
          var marker = new AMap.Marker({
            position: new AMap.LngLat(113.856018, 22.588541),
          });
          this.map.add(marker);
          var info = [];
          info.push(
            '<div style="padding:0px 0px 0px 4px;"><b>深圳佳智惠软件有限公司</b>'
          );
          info.push(`电话 : ${companyInfoList.title}`);
          info.push(`地址 : ${companyInfoList.description}</div></div>`);
          this.infoWindow = new AMap.InfoWindow({
            content: info.join("<br/>"), //使用默认信息窗体框样式，显示信息内容
          });
          this.infoWindow.open(this.map, [113.856018, 22.588541]);
        })
        .catch((e) => { });
    },
    /**
     * 点击标签
     * @param item
     * @param index
     */
    addTab (item, index) {
      this.tabShou = index;
      this.titleImg = item.img;
      this.location = item.name;
      if (index === 3) {
        this.init();
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.about-map {
  width: 100%;
  height: 500px;
  margin-top: 30px;
  #allmap {
    height: 500px;
  }
}
.about-cen {
  padding: 0px 100px;
  display: flex;
  margin-bottom: 50px;
  .tab {
    color: #000;
    width: 280px;
    background: #eee;
    padding-top: 40px;
    .breadcrumb {
      margin-left: 10%;
      margin-bottom: 20px;
    }
    .title-box {
      // text-align: -webkit-center;
      margin-left: 50%;
      .title {
        width: 100px;
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #7f0114;
      }
      .name {
        width: 100px;
        font-size: 10px;
        padding-left: 10px;
        color: rgb(96, 94, 94);
      }
    }
    .tab-box {
      margin-top: 20px;
      .active {
        background: #7f0114;
        color: #fff;
        z-index: 99;
      }
      ul li {
        width: 160px;
        height: 35px;
        line-height: 35px;
        margin-left: 50%;
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
  .center {
    padding: 0 90px;
    width: 83%;
    background-image: url("../../assets/serve/bh33333.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    dt {
      font-size: 16px;
      font-weight: bold;
    }
    .agent-form {
      display: flex;
      .agent-box {
        width: 100%;
      }
      img {
        width: 60%;
        height: 400px;
      }
    }
    .title-img {
      margin: auto;
      margin-bottom: 20px;
      margin-top: 30px;
      width: 220px;
      height: 70px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .description {
      margin-bottom: 20px;
    }
    .img {
      width: 100%;
    }

    .qualification-box {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: center;
      .qualification {
        width: 200px;
        .img {
          width: 100%;
        }
        .qname {
          width: 100%;
          text-align: center;
          padding: 0 10px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .about-bottom {
      font-size: 16px;
      i {
        font-size: 25px;
      }
    }
  }
}
.honor {
  width: 100%;
}
.honor {
  padding-bottom: 40px;
  padding-top: 50px;
  .swiper-wrapper {
    width: 81%;
  }
  .swioer-item {
    width: 260px !important;
  }
}
.tab-box1 {
  display: none;
}
@media screen and (max-width: 750px) {
  .about-cen {
    padding: 0;
    .center {
      padding: 0 15px;
      width: 100%;
    }
  }
  .agent-form {
    img {
      display: none;
    }
  }
  .tab {
    display: none;
  }
  .qualification-box {
    img {
      width: 48% !important;
    }
    .qualification {
      width: 50% !important;
    }
  }
  .tab-box1 {
    display: block;
    padding-top: 10px;
    ul {
      display: flex;
      justify-content: space-around;
      li {
        font-size: 16px;
      }
      .active {
        color: #7f0114;
        border-bottom: 1px solid #7f0114;
      }
    }
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
